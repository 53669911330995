import {call, put, takeLatest} from "redux-saga/effects";
import {getArtistToken} from "../../../auth/token.saga";
import {extractDataFromResponse} from "../../../saga-utils/httpInterceptorWrapper";
import {getVoucherListByDatOutVonBis} from "../../../../api/voucherApi";
import {
    LOAD_VOUCHERS_LIST_DATA,
    loadVouchersListDataFailureAction,
    loadVouchersListDataSuccessAction
} from "../../listActions";
import {emitNotificationAction} from "../../../notification/notificationAction";
import {createErrorNotification} from "../../../../models/notification";
import i18next from "i18next";
import {i18_LIST} from "../../../../i18nReferences";

function* processLoadVouchersListData({payload}) {
    try {
        const token = yield getArtistToken()
        if (token) {
            const parameters = payload.parameters
            const vouchers = extractDataFromResponse(yield call(getVoucherListByDatOutVonBis, {token, ...parameters}))
            yield put(loadVouchersListDataSuccessAction(vouchers))
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('CHECKOUT_LIST.LOAD_VOUCHERS_DATA_FAILED_INFO', {ns: i18_LIST}), 'loadVoucherListData')))
        yield put(loadVouchersListDataFailureAction())
        console.log(err)
    }
}

export function* watchLoadVouchersListData() {
    yield takeLatest(LOAD_VOUCHERS_LIST_DATA, processLoadVouchersListData)
}