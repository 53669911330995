export const SEND_MESSAGE_PATH = '/sendmessage'
export const TEMPLATE_BASE_PATH = '/templates'
export const MESSAGES_SUBPATH = 'messages'
export const PDF_TEMPLATE_SUBPATH = 'pdf'
export const FORMS_TEMPLATE_SUBPATH = 'forms'
export const FORMS_EDITOR_PATH = 'formseditor'
export const AUTO_MESSAGE_SETTINGS_SUBPATH = MESSAGES_SUBPATH + '/' + 'auto'
export const AUTO_MESSAGE_SETTINGS_FULLPATH = TEMPLATE_BASE_PATH + '/' + AUTO_MESSAGE_SETTINGS_SUBPATH
export const PDF_TEMPLATE_LIST_FULLPATH = TEMPLATE_BASE_PATH + '/' + PDF_TEMPLATE_SUBPATH
export const FORMS_TEMPLATE_LIST_FULLPATH = TEMPLATE_BASE_PATH + '/' + FORMS_TEMPLATE_SUBPATH

export const PDFSIGN_BASEPATH = '/pdfsign'
export const PDFSIGN_AUTO_DELIVERY_SUBPATH = 'autodelivery'
export const PDFSIGN_AUTO_DELIVERY_FULLPATH = PDFSIGN_BASEPATH + '/' + PDFSIGN_AUTO_DELIVERY_SUBPATH

export const STUDIO_BASEPATH = '/studio'
export const STUDIO_BASE_SUBPATH = 'base'
export const STUDIO_BASE_FULLPATH =  [STUDIO_BASEPATH, STUDIO_BASE_SUBPATH].join('/')
export const STUDIO_SUBSCRIPTION_SUBPATH = 'subscription'
export const STUDIO_ABO_FULLPATH = [STUDIO_BASEPATH, STUDIO_SUBSCRIPTION_SUBPATH].join('/')
export const CUSTOMER_PORTAL_BASEPATH = '/customer-portal'
export const CUSTOMER_PORTAL_CONFIGURATION_SUBPATH = 'configuration'
export const CUSTOMER_PORTAL_CATEGORY_SUBPATH = 'category'
export const CUSTOMER_PORTAL_APPT_TYPE_SUBPATH = 'appointment-type'
export const CUSTOMER_PORTAL_PRODUCT_SUBPATH = 'product'
export const CUSTOMER_PORTAL_ARTIST_SUBPATH = 'artist'

export const STUDIO_LOCATIONS_SUBPATH = 'locations'
export const STUDIO_LOCATIONS_FULLPATH = [STUDIO_BASEPATH, STUDIO_LOCATIONS_SUBPATH].join('/')
export const STUDIO_APPOINTMENT_TYPES_SUBPATH = 'appointment-types'
export const STUDIO_APPOINTMENT_TYPES_FULLPATH = [STUDIO_BASEPATH, STUDIO_APPOINTMENT_TYPES_SUBPATH].join('/')
export const STUDIO_GGL_SYNC_SUBPATH = 'ggl-sync'
export const STUDIO_GGL_SYNC_FULLPATH = [STUDIO_BASEPATH, STUDIO_GGL_SYNC_SUBPATH].join('/')
export const STUDIO_V2_API_SUBPATH = 'v2-api'
export const STUDIO_V2_API_FULLPATH = [STUDIO_BASEPATH, STUDIO_V2_API_SUBPATH].join('/')

export const KUNDE = '/kunde'
export const KUNDEN_IMPORT = KUNDE + '/import'

export const TERMIN = '/termin'
export const TERMIN_ICALIMPORT = TERMIN + '/icalimport'
