import {fork} from "redux-saga/effects";
import {watchLoadKundenListData} from "./sagas/kunden/loadKundenList";
import {watchLoadProjektListData} from "./sagas/projekt/loadProjektListData";
import {watchLoadTerminListData} from "./sagas/termin/loadTerminListData";
import {watchLoadEinnahmenListData} from "./sagas/einnahmen/loadEinnahmenListData";
import {watchLoadCheckoutListData} from "./sagas/checkout/loadCheckoutListData";
import {watchLoadVouchersListData} from "./sagas/voucher/loadVouchersListData";
import {watchLoadReportDefinition} from "./sagas/lists/loadReportDefinition";
import {watchLoadAllReportDefinition} from "./sagas/lists/loadAllReportDefinition";
import {watchSaveListDefinition} from "./sagas/lists/saveListDefinition";
import {watchCopyListDefinitionAndNavigate} from "./sagas/lists/copyListDefinitionAndNavigate";
import {watchDeleteListDefinition} from "./sagas/lists/deleteListDefinition";
import {watchSaveProjekt} from "./sagas/projekt/saveProjekt";
import {watchBroadcastKunden} from "./sagas/kunden/broadcastKunden";
import {watchLoadMandantenJobsListData} from "./sagas/jobs/loadMandantenJobsListData";
import {watchCleanupJobs} from "./sagas/jobs/cleanupJobs";
import {watchLoadJobDetails} from "./sagas/jobs/LoadJobDetails";
import {watchAddStandardLists} from "./sagas/lists/addStandardLists";
import {watchUpdateProjectList} from "./sagas/projekt/updateProjectList";
import {watchUpdateTerminList} from "./sagas/termin/updateTerminList";
import {watchLoadVouchInListData} from "./sagas/voucher/loadVouchInListData";
import {watchLoadDblExclusionListData} from "./sagas/kunden/dblExclusionsList";
import {watchDeleteDblExclusion} from "./sagas/kunden/deleteDblExclusion";
import {watchLoadDoublettenListData} from "./sagas/kunden/doublettenList";
import {watchAddExclusions} from "./sagas/kunden/addDblExclusions";
import {watchLoadMergeKundenData} from "./sagas/kunden/loadMergeKundenData";
import {watchMergeDoublets} from "./sagas/kunden/mergeDoublets";
import {watchUpdateProjektSuccess} from "./sagas/projekt/reloadAfterUpdateProjekt";
import {watchLoadNachrichtenListData} from "./sagas/nachrichten/loadNachrichtenListData";
import {watchDeleteNachrichten} from "./sagas/nachrichten/deleteNachrichten";

const listsSaga = [fork(watchLoadKundenListData), fork(watchLoadReportDefinition), fork(watchLoadAllReportDefinition), fork(watchSaveListDefinition)
    , fork(watchDeleteListDefinition), fork(watchCopyListDefinitionAndNavigate), fork(watchLoadProjektListData), fork(watchSaveProjekt)
    , fork(watchBroadcastKunden), fork(watchLoadMandantenJobsListData), fork(watchCleanupJobs), fork(watchLoadJobDetails), fork(watchAddStandardLists)
    , fork(watchLoadTerminListData), fork(watchLoadCheckoutListData), fork(watchUpdateProjectList), fork(watchUpdateTerminList), fork(watchLoadVouchersListData)
    , fork(watchLoadEinnahmenListData), fork(watchLoadVouchInListData), fork(watchLoadDblExclusionListData), fork(watchDeleteDblExclusion), fork(watchLoadDoublettenListData)
    , fork(watchAddExclusions), fork(watchLoadMergeKundenData), fork(watchMergeDoublets), fork(watchUpdateProjektSuccess), fork(watchLoadNachrichtenListData)
    , fork(watchDeleteNachrichten)]

export default listsSaga