import {CHANGE_VIEW_TYPE, initiateLoadCalendarAction} from "../calendarActions";
import {SINGLE_ARTIST_VIEW} from "../CalendarReducer";
import {selectCalendarEventZeitraum} from "../calendarSelector";
import {changeZeitraumForResourceView, getWeekFromDate} from "../../utils/period";
import {put, select, takeLatest} from "redux-saga/effects"
import {PlainDate} from "@kiss-solutions/plaindate";

function* adaptSelectedZeitraumForChangedViewType({payload}) {
    const isSingle = payload.viewType === SINGLE_ARTIST_VIEW
    let zeitraum = yield select(state => selectCalendarEventZeitraum(state))
    zeitraum = isSingle ? getWeekFromDate(new PlainDate(zeitraum.start))
        : changeZeitraumForResourceView(zeitraum)
    yield put(initiateLoadCalendarAction(zeitraum))
}

export function* changeViewTypeSaga() {
    yield takeLatest(CHANGE_VIEW_TYPE, adaptSelectedZeitraumForChangedViewType)
}
