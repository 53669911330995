import {getArtistToken} from "../auth/token.saga";
import {extractDataFromResponse} from "../saga-utils/httpInterceptorWrapper";
import {getVoucherByVouchNo, insertVoucher, updateVoucher} from "../../api/voucherApi";
import {
    LOAD_VOUCHER_DATA,
    loadVoucherDataFailureAction,
    loadVoucherDataSuccessAction,
    SAVE_VOUCHER
} from "./vouchersAction";
import {call, put, takeLatest, fork, select} from "redux-saga/effects";
import {emitNotificationAction} from "../notification/notificationAction";
import {createErrorNotification, createSuccessNotification} from "../../models/notification";
import i18next from "i18next";
import {selectVouchersListParameters} from "../list/listSelector";
import {loadVouchersListDataAction} from "../list/listActions";

export function* processLoadVoucherData({payload}) {
    try {
        const voucherNo = payload.voucherNo
        const token = yield getArtistToken()
        if (token) {
            const voucher = extractDataFromResponse(yield call(getVoucherByVouchNo, {token, voucherNo}))
            yield put(loadVoucherDataSuccessAction(voucher))
        }
    } catch (err) {
        yield put(loadVoucherDataFailureAction())
        yield put(emitNotificationAction(createErrorNotification(i18next.t('VOUCHER.LOAD_VOUCHER_DATA_FAILURE_INFO'), 'loadVoucher')))
        console.log(err)
    }
}

export function* watchLoadVoucherData() {
    yield takeLatest(LOAD_VOUCHER_DATA, processLoadVoucherData)
}

function* processSaveVoucher({payload}) {
    try {
        const token = yield getArtistToken()
        const voucher = payload.voucher
        let savedVoucher = null
        const vouchno = voucher.VouchNo
        if (token) {
            if (vouchno) {
                savedVoucher = extractDataFromResponse(yield call(updateVoucher, {token, voucher}))
            } else {
                savedVoucher = extractDataFromResponse(yield call(insertVoucher, {token, voucher}))
            }
            yield put(emitNotificationAction(createSuccessNotification(i18next.t('VOUCHERS_LIST.VOUCHER_SAVE_SUCCESS_INFO', {
                ns: 'vouchers',
                vouchno: savedVoucher.VouchNo
            }), 'voucherListUpdate')))
            const parameters = yield select(state => selectVouchersListParameters(state))
            if (parameters) {
                yield put(loadVouchersListDataAction(parameters))
            }
        }
    } catch (err) {
        yield put(emitNotificationAction(createErrorNotification(i18next.t('VOUCHERS_LIST.VOUCHER_SAVE_FAILED',
            {ns: 'vouchers'}), 'voucherListUpdate')))
        console.log(err)
    }
}

function* watchSaveVoucher() {
    yield takeLatest(SAVE_VOUCHER, processSaveVoucher)
}

const voucherSagas = [fork(watchLoadVoucherData), fork(watchSaveVoucher)]

export default voucherSagas