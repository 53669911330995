import {
    ADD_VOUCHER_DATA,
    CLEAR_VOUCHER_DATA,
    LOAD_VOUCHER_DATA,
    LOAD_VOUCHER_DATA_FAILURE,
    LOAD_VOUCHER_DATA_SUCCESS
} from "./vouchersAction";

const INITIAL = {}

export default function voucherReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_VOUCHER_DATA:
            return {
                ...state
                , voucherLoading: true
                , voucherId: action.payload.voucherId
                , voucherData: undefined
            }
        case LOAD_VOUCHER_DATA_SUCCESS:
        case ADD_VOUCHER_DATA:
            return {
                ...state
                , voucherLoading: false
                , voucherData: action.payload.voucherData
            }
        case LOAD_VOUCHER_DATA_FAILURE:
            return {
                ...state
                , voucherLoading: false
            }
        case CLEAR_VOUCHER_DATA:
            return INITIAL
        default:
            return INITIAL
    }


}