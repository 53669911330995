import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_KUNDEN_LIST_DATA = 'LOAD_KUNDEN_LIST_DATA'
export const loadKundenListDataAction = createSimpleAction(LOAD_KUNDEN_LIST_DATA, 'kundenListParameter')

export const LOAD_KUNDEN_LIST_DATA_SUCCESS = 'LOAD_KUNDEN_LIST_DATA_SUCCESS'
export const loadKundenListDataSuccessAction = createSimpleAction(LOAD_KUNDEN_LIST_DATA_SUCCESS, 'kundenListData')

export const LOAD_KUNDEN_LIST_DATA_FAILURE = 'LOAD_KUNDEN_LIST_DATA_FAILURE'
export const loadKundenListDataFailureAction = createTypeAction(LOAD_KUNDEN_LIST_DATA_FAILURE)

export const LOAD_PROJEKT_LIST_DATA = 'LOAD_PROJEKT_LIST_DATA'
export const loadProjektListDataAction = createSimpleAction(LOAD_PROJEKT_LIST_DATA, 'parameters')

export const LOAD_PROJEKT_LIST_DATA_SUCCESS = 'LOAD_PROJEKT_LIST_DATA_SUCCESS'
export const loadProjektListDataSuccessAction = createSimpleAction(LOAD_PROJEKT_LIST_DATA_SUCCESS, 'projektListData')

export const LOAD_PROJEKT_LIST_DATA_FAILURE = 'LOAD_PROJEKT_LIST_DATA_FAILURE'
export const loadProjektListDataFailureAction = createTypeAction(LOAD_PROJEKT_LIST_DATA_FAILURE)

export const LOAD_TERMIN_LIST_DATA = 'LOAD_TERMIN_LIST_DATA'
export const loadTerminListDataAction = createSimpleAction(LOAD_TERMIN_LIST_DATA, 'parameters')

export const LOAD_TERMIN_LIST_DATA_SUCCESS = 'LOAD_TERMIN_LIST_DATA_SUCCESS'
export const loadTerminListDataSuccessAction = createSimpleAction(LOAD_TERMIN_LIST_DATA_SUCCESS, 'terminListData')

export const LOAD_TERMIN_LIST_DATA_FAILURE = 'LOAD_TERMIN_LIST_DATA_FAILURE'
export const loadTerminListDataFailureAction = createTypeAction(LOAD_TERMIN_LIST_DATA_FAILURE)

export const LOAD_EINNAHMEN_LIST_DATA = 'LOAD_EINNAHMEN_LIST_DATA'
export const loadEinnahmenListDataAction = createSimpleAction(LOAD_EINNAHMEN_LIST_DATA, 'parameters')

export const LOAD_EINNAHMEN_LIST_DATA_SUCCESS = 'LOAD_EINNAHMEN_LIST_DATA_SUCCESS'
export const loadEinnahmenListDataSuccessAction = createSimpleAction(LOAD_EINNAHMEN_LIST_DATA_SUCCESS, 'einnahmenListData')

export const LOAD_EINNAHMEN_LIST_DATA_FAILURE = 'LOAD_EINNAHMEN_LIST_DATA_FAILURE'
export const loadEinnahmenListDataFailureAction = createTypeAction(LOAD_EINNAHMEN_LIST_DATA_FAILURE)

export const LOAD_CHECKOUT_LIST_DATA = 'LOAD_CHECKOUT_LIST_DATA'
export const loadCheckoutListDataAction = createSimpleAction(LOAD_CHECKOUT_LIST_DATA, 'parameters')

export const LOAD_CHECKOUT_LIST_DATA_SUCCESS = 'LOAD_CHECKOUT_LIST_DATA_SUCCESS'
export const loadCheckoutListDataSuccessAction = createSimpleAction(LOAD_CHECKOUT_LIST_DATA_SUCCESS, 'checkoutListData')

export const LOAD_CHECKOUT_LIST_DATA_FAILURE = 'LOAD_CHECKOUT_LIST_DATA_FAILURE'
export const loadCheckoutListDataFailureAction = createTypeAction(LOAD_CHECKOUT_LIST_DATA_FAILURE)

export const CLEAR_LIST_DATA = 'CLEAR_LIST_DATA'
export const clearListData = createTypeAction(CLEAR_LIST_DATA)

export const SET_SELECTED_LIST_ID_LIST = 'SET_SELECTED_LIST_ID_LIST'
export const setSelectedListIdList = createSimpleAction(SET_SELECTED_LIST_ID_LIST, 'selectedListIdList')

export const LOAD_REPORT_DEFINITION = 'LOAD_REPORT_DEFINITION'
export const loadReportDefinitionAction = createSimpleAction(LOAD_REPORT_DEFINITION, 'reportDefID')

export const LOAD_REPORT_DEFINITION_SUCCESS = 'LOAD_REPORT_DEFINITION_SUCCESS'
export const loadReportDefinitionSuccessAction = createSimpleAction(LOAD_REPORT_DEFINITION_SUCCESS, 'reportDefinition')

export const LOAD_REPORT_DEFINITION_FAILURE = 'LOAD_REPORT_DEFINITION_FAILURE'
export const loadReportDefinitionFailureAction = createTypeAction(LOAD_REPORT_DEFINITION_FAILURE)

export const LOAD_ALL_REPORT_DEFINITIONS = 'LOAD_ALL_REPORT_DEFINITIONS'
export const loadAllReportDefinitionsAction = createTypeAction(LOAD_ALL_REPORT_DEFINITIONS)

export const LOAD_ALL_REPORT_DEFINITIONS_SUCCESS = 'LOAD_ALL_REPORT_DEFINITIONS_SUCCESS'
export const loadAllReportDefinitionsSuccessAction = createSimpleAction(LOAD_ALL_REPORT_DEFINITIONS_SUCCESS, 'allReportDefinitions')

export const LOAD_ALL_REPORT_DEFINITIONS_FAILURE = 'LOAD_ALL_REPORT_DEFINITIONS_FAILURE'
export const loadAllReportDefinitionsFailureAction = createTypeAction(LOAD_ALL_REPORT_DEFINITIONS_FAILURE)

export const SAVE_LIST_DEFINITION = 'SAVE_LIST_DEFINITION'
export const saveListDefinitionAction = createSimpleAction(SAVE_LIST_DEFINITION, 'reportDefinition')

export const COPY_LIST_DEFINITION_AND_NAVIGATE_TO = 'COPY_LIST_DEFINITION_AND_NAVIGATE_TO'
export const copyListDefinitionAndNavigateToAction = createSimpleAction(COPY_LIST_DEFINITION_AND_NAVIGATE_TO, 'reportDefinition')

export const ADD_STANDARD_LISTS = 'ADD_STANDARD_LISTS'
export const addStandardListsAction = createSimpleAction(ADD_STANDARD_LISTS, 'listIds')

export const DELETE_LIST_DEFINITION = 'DELETE_LIST_DEFINITION'
export const deleteListDefinitionAction = createSimpleAction(DELETE_LIST_DEFINITION, 'reportDefID')

export const SET_PARAMETERS_KUNDEN_LIST = 'SET_PARAMETERS_KUNDEN_LIST'
export const setParametersKundenList = createSimpleAction(SET_PARAMETERS_KUNDEN_LIST,'kundenListParameter', {})

export const SET_SELECTED_ARTISTID_LIST_PROJEKT_LIST = 'SET_SELECTED_ARTISTID_LIST_PROJEKT_LIST'
export const setSelectedArtistIDListForProjektList = createSimpleAction(SET_SELECTED_ARTISTID_LIST_PROJEKT_LIST,'selectedArtistIdList')

export const SET_SELECTED_STATUS_LIST_PROJEKT_LIST = 'SET_SELECTED_STATUS_LIST_PROJEKT_LIST'
export const setSelectedStatusListForProjektList = createSimpleAction(SET_SELECTED_STATUS_LIST_PROJEKT_LIST,'projektStatusListe')

export const SET_DATVON_FOR_PROJEKT_LIST = 'SET_DATVON_FOR_PROJEKT_LIST'
export const setDatVonForProjektList = createSimpleAction(SET_DATVON_FOR_PROJEKT_LIST,'datVon')

export const SET_DATBIS_FOR_PROJEKT_LIST = 'SET_DATBIS_FOR_PROJEKT_LIST'
export const setDatBisForProjektList = createSimpleAction(SET_DATBIS_FOR_PROJEKT_LIST,'datBis')

export const SET_WITHOUT_APPOINTMENT_FLAG_PROJEKT_LIST = 'SET_WITHOUT_APPOINTMENT_FLAG_PROJEKT_LIST'
export const setWithoutAppointmentFlagForProjektList = createSimpleAction(SET_WITHOUT_APPOINTMENT_FLAG_PROJEKT_LIST,'withoutAppointments')

export const SAVE_PROJEKT_AND_RELOAD_LIST = 'SAVE_PROJEKT_AND_RELOAD_LIST'
export const saveProjektAndReloadList = createSimpleAction(SAVE_PROJEKT_AND_RELOAD_LIST, 'projekt')

export const SET_PARAMETERS_TERMIN_LIST = 'SET_PARAMETERS_TERMIN_LIST'
export const setParametersTerminList = createSimpleAction(SET_PARAMETERS_TERMIN_LIST,'terminListParameter', {})

export const SET_PARAMETERS_EINNAHMEN_LIST = 'SET_PARAMETERS_EINNAHMEN_LIST'
export const setParametersEinnahmenList = createSimpleAction(SET_PARAMETERS_EINNAHMEN_LIST,'einnahmenListParameter', {})

export const INITIATE_BROADCAST_KUNDEN_JOB = 'INITIATE_BROADCAST_KUNDEN_JOB'
export const initiateBroadcastKundenJob = createSimpleAction(INITIATE_BROADCAST_KUNDEN_JOB, 'parameters')

export const LOAD_MANDANTEN_JOBS_DATA = 'LOAD_MANDANTEN_JOBS_DATA'
export const loadMandantenJobsAction = createSimpleAction(LOAD_MANDANTEN_JOBS_DATA, 'parameters')

export const LOAD_MANDANTEN_JOBS_SUCCESS = 'LOAD_MANDANTEN_JOBS_SUCCESS'
export const loadMandantenJobsSuccessAction = createSimpleAction(LOAD_MANDANTEN_JOBS_SUCCESS, 'mandantenJobsListData')

export const LOAD_MANDANTEN_JOBS_FAILURE = 'LOAD_MANDANTEN_JOBS_FAILURE'
export const loadMandantenJobsFailureAction = createTypeAction(LOAD_MANDANTEN_JOBS_FAILURE)

export const SET_PARAMETERS_JOBS_LIST = 'SET_PARAMETERS_JOBS_LIST'
export const setParametersJobsList = createSimpleAction(SET_PARAMETERS_JOBS_LIST,'jobsListParameter', {})

export const CLEANUP_JOBS = 'CLEANUP_JOBS'
export const cleanupJobsAction = createSimpleAction(CLEANUP_JOBS, 'parameters')

export const LOAD_JOB_DETAILS = 'LOAD_JOB_DETAILS'
export const loadJobDetailAction = createSimpleAction(LOAD_JOB_DETAILS, 'job')

export const LOAD_JOB_DETAILS_SUCCESS = 'LOAD_JOB_DETAILS_SUCCESS'
export const loadJobDetailSuccessAction = createSimpleAction(LOAD_JOB_DETAILS_SUCCESS, 'jobDetails')

export const LOAD_JOB_DETAILS_FAILURE = 'LOAD_JOB_DETAILS_FAILURE'
export const loadJobDetailFailureAction = createTypeAction(LOAD_JOB_DETAILS_FAILURE)

export const CLEAR_JOB_DETAILS = 'CLEAR_JOB_DETAILS'
export const clearJobDetailsAction = createTypeAction(CLEAR_JOB_DETAILS)

export const UPDATE_PROJECT_STATUS_FOR_LIST = 'UPDATE_PROJECT_STATUS_FOR_LIST'
export const updateProjectStatusForListAction = createSimpleAction(UPDATE_PROJECT_STATUS_FOR_LIST, 'projectChanges')

export const UPDATE_TERMIN_STATUS_FOR_LIST = 'UPDATE_TERMIN_STATUS_FOR_LIST'
export const updateTerminStatusForListAction = createSimpleAction(UPDATE_TERMIN_STATUS_FOR_LIST, 'terminChanges')

export const SET_PARAMETERS_VOUCHERS_LIST = 'SET_PARAMETERS_VOUCHERS_LIST'
export const setParametersVouchersList = createSimpleAction(SET_PARAMETERS_VOUCHERS_LIST,'vouchersListParameter', {})

export const LOAD_VOUCHERS_LIST_DATA = 'LOAD_VOUCHERS_LIST_DATA'
export const loadVouchersListDataAction = createSimpleAction(LOAD_VOUCHERS_LIST_DATA, 'parameters')

export const LOAD_VOUCHERS_LIST_DATA_SUCCESS = 'LOAD_VOUCHERS_LIST_DATA_SUCCESS'
export const loadVouchersListDataSuccessAction = createSimpleAction(LOAD_VOUCHERS_LIST_DATA_SUCCESS, 'vouchersListData')

export const LOAD_VOUCHERS_LIST_DATA_FAILURE = 'LOAD_VOUCHERS_LIST_DATA_FAILURE'
export const loadVouchersListDataFailureAction = createTypeAction(LOAD_VOUCHERS_LIST_DATA_FAILURE)

export const SET_PARAMETERS_VOUCHIN_LIST = 'SET_PARAMETERS_VOUCHIN_LIST'
export const setParametersVouchInList = createSimpleAction(SET_PARAMETERS_VOUCHIN_LIST,'vouchInListParameter', {})

export const LOAD_VOUCHIN_LIST_DATA = 'LOAD_VOUCHIN_LIST_DATA'
export const loadVouchInListDataAction = createSimpleAction(LOAD_VOUCHIN_LIST_DATA, 'parameters')

export const LOAD_VOUCHIN_LIST_DATA_SUCCESS = 'LOAD_VOUCHIN_LIST_DATA_SUCCESS'
export const loadVouchInListDataSuccessAction = createSimpleAction(LOAD_VOUCHIN_LIST_DATA_SUCCESS, 'vouchInListData')

export const LOAD_VOUCHIN_LIST_DATA_FAILURE = 'LOAD_VOUCHIN_LIST_DATA_FAILURE'
export const loadVouchInListDataFailureAction = createTypeAction(LOAD_VOUCHIN_LIST_DATA_FAILURE)

export const LOAD_DBLEXCLUSION_LIST_DATA = 'LOAD_DBLEXCLUSION_LIST_DATA'
export const loadDblExclusionListDataAction = createSimpleAction(LOAD_DBLEXCLUSION_LIST_DATA, 'parameters')

export const LOAD_DBLEXCLUSION_LIST_DATA_SUCCESS = 'LOAD_DBLEXCLUSION_LIST_DATA_SUCCESS'
export const loadDblExclusionListDataSuccessAction = createSimpleAction(LOAD_DBLEXCLUSION_LIST_DATA_SUCCESS, 'dblExclusionListData')

export const LOAD_DBLEXCLUSION_LIST_DATA_FAILURE = 'LOAD_DBLEXCLUSION_LIST_DATA_FAILURE'
export const loadDblExclusionListDataFailureAction = createTypeAction(LOAD_DBLEXCLUSION_LIST_DATA_FAILURE)

export const SET_PARAMETERS_DBLEXCLUSION_LIST = 'SET_PARAMETERS_DBLEXCLUSION_LIST'
export const setParametersDblExclusionList = createSimpleAction(SET_PARAMETERS_DBLEXCLUSION_LIST,'dblExclusionListParameter', {})

export const DELETE_DBLEXCLUSION = 'DELETE_DBLEXCLUSION'
export const deleteDblExclusionAction = createSimpleAction(DELETE_DBLEXCLUSION, 'kdExDblId')

export const ADD_EXCLUSIONS = 'ADD_EXCLUSIONS'
export const addExclusionsAction = createSimpleAction(ADD_EXCLUSIONS, 'exclusionData')

export const LOAD_DOUBLETTEN_LIST_DATA = 'LOAD_DOUBLETTEN_LIST_DATA'
export const loadDoublettenListDataAction = createTypeAction(LOAD_DOUBLETTEN_LIST_DATA)

export const LOAD_DOUBLETTEN_LIST_DATA_SUCCESS = 'LOAD_DOUBLETTEN_LIST_DATA_SUCCESS'
export const loadDoublettenListDataSuccessAction = createSimpleAction(LOAD_DOUBLETTEN_LIST_DATA_SUCCESS, 'doublettenListData')

export const LOAD_DOUBLETTEN_LIST_DATA_FAILURE = 'LOAD_DOUBLETTEN_LIST_DATA_FAILURE'
export const loadDoublettenListDataFailureAction = createTypeAction(LOAD_DOUBLETTEN_LIST_DATA_FAILURE)

export const LOAD_DOUBLETTEN_MERGE_KUNDEN = 'LOAD_DOUBLETTEN_MERGE_KUNDEN'
export const loadDoublettenMergeKundenAction = createSimpleAction(LOAD_DOUBLETTEN_MERGE_KUNDEN, 'kundIdPair')

export const LOAD_DOUBLETTEN_MERGE_KUNDEN_SUCCESS = 'LOAD_DOUBLETTEN_MERGE_KUNDEN_SUCCESS'
export const loadDoublettenMergeKundenSuccessAction = createSimpleAction(LOAD_DOUBLETTEN_MERGE_KUNDEN_SUCCESS, 'mergeKundenData')

export const LOAD_DOUBLETTEN_MERGE_KUNDEN_FAILURE = 'LOAD_DOUBLETTEN_MERGE_KUNDEN_FAILURE'
export const loadDoublettenMergeKundenFailureAction = createTypeAction(LOAD_DOUBLETTEN_MERGE_KUNDEN_FAILURE)

export const CLEAR_DOUBLETTEN_MERGE_KUNDEN_DATA = 'CLEAR_DOUBLETTEN_MERGE_KUNDEN_DATA'
export const clearDoublettenMergeKundenDataAction = createTypeAction(CLEAR_DOUBLETTEN_MERGE_KUNDEN_DATA)

export const MERGE_DOUBLETS = 'MERGE_DOUBLETS'
export const mergeDoubletsAction = createSimpleAction(MERGE_DOUBLETS, 'mergeData')


export const LOAD_NACHRICHTEN_LIST_DATA = 'LOAD_NACHRICHTEN_LIST_DATA'
export const loadNachrichtenListDataAction = createSimpleAction(LOAD_NACHRICHTEN_LIST_DATA, 'nachrichtenListParameter')

export const LOAD_NACHRICHTEN_LIST_DATA_SUCCESS = 'LOAD_NACHRICHTEN_LIST_DATA_SUCCESS'
export const loadNachrichtenListDataSuccessAction = createSimpleAction(LOAD_NACHRICHTEN_LIST_DATA_SUCCESS, 'nachrichtenListData')

export const LOAD_NACHRICHTEN_LIST_DATA_FAILURE = 'LOAD_NACHRICHTEN_LIST_DATA_FAILURE'
export const loadNachrichtenListDataFailureAction = createTypeAction(LOAD_NACHRICHTEN_LIST_DATA_FAILURE)

export const SET_PARAMETERS_NACHRICHTEN_LIST = 'SET_PARAMETERS_NACHRICHTEN_LIST'
export const setParametersNachrichtenList = createSimpleAction(SET_PARAMETERS_NACHRICHTEN_LIST,'nachrichtenListParameter', {})

export const DELETE_NACHRICHTEN = 'DELETE_NACHRICHTEN'
export const deleteNachrichtenAction = createSimpleAction(DELETE_NACHRICHTEN, 'nachrichtenIdList')