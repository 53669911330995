import {combineReducers} from "redux";
import commonReducers from './common/commonReducer';
import authReducer from "./auth/authReducer";
import routerReducer from "./router/routerReducers";
import calendarReducer from "./calendar/CalendarReducer";
import kundeReducers from "./kunde/kundeReducer";
import finalizerReducers from "./finalizer/finalizerReducer";
import manageProjekteReducers from "./manage/projekte/manageProjekteReducer";
import nachrichtReducers from "./nachricht/nachrichtReducer";
import notificationReducers from "./notification/notificationReducer";
import kundenTermineReducers from "./kundenTermine/kundenTermineReducer";
import terminReducers from "./termin/terminReducer";
import artistEditReducers from "./artist/artistReducer";
import listReducers from "./list/listReducer";
import projektReducers from "./projekt/projektReducer";
import voucherReducers from "./vouchers/vouchersReducer";
import templateReducers from "./templates/templateReducer";
import pdfSignReducers from "./settings/pdfsign/pdfSignReducer";
import subscriptionReducers from "./settings/subscription/subscriptionReducer";
import timelineReducers from "./timeline/timelineReducers";

const createReducers = () => combineReducers({
    router: routerReducer,
    common: commonReducers,
    auth: authReducer,
    termin: terminReducers,
    kunde: kundeReducers,
    kundenTermine: kundenTermineReducers,
    calendar: calendarReducer,
    finalizer: finalizerReducers,
    manageProjekte: manageProjekteReducers,
    nachricht: nachrichtReducers,
    notification: notificationReducers,
    pdfSignSettings: pdfSignReducers,
    artistEdit: artistEditReducers,
    lists: listReducers,
    projekt: projektReducers,
    voucher: voucherReducers,
    vorlagen: templateReducers,
    subscription: subscriptionReducers,
    timeline: timelineReducers
});

export default createReducers;
