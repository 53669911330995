import {convertStringToNumber} from "../store/utils/numericHelper";
import * as yup from "yup";
import {i18_LIST, i18_TRANSLATION} from "../i18nReferences";
import {createValueFormatter} from "./lists";
import {convertDateStringToDate} from "./date";

export const getOffenerBetrag = voucher => {
    const betrag = voucher.buchungen ? voucher.buchungen.reduce((pv, cv) => pv - convertStringToNumber(cv.Amount, 0)
            , convertStringToNumber(voucher.Amount, 0))
        : convertStringToNumber(voucher.Amount, 0)
    return Math.round(betrag * 100) / 100
}

export const createVouchIn = (VouchOutID, Amount, Encashed, _createdID, DatIn) => {
    return {
        VouchOutID
        , Amount
        , Encashed
        , DatIn
        , _createdID
        , _createdTS: new Date()
    }
}

export const createVouchOut = (loggedInArtistID) => {
    return {
        VouchOutID: -1
        , Amount: 0
        , DatOut: new Date()
        , ArtistID: ''
        , Receiver: ''
        , _createdID: loggedInArtistID
        , _createdTS: new Date()
    }
}

export const convertVouchOutToSchema = (voucher) => ({
    ...voucher,
    DatOut: convertDateStringToDate(voucher.DatOut),
    _createdTS: convertDateStringToDate(voucher._createdTS)
})

export const convertVouchInToSchema = (voucher) => ({
    ...voucher,
    DatIn: convertDateStringToDate(voucher.DatIn),
    _createdTS: convertDateStringToDate(voucher._createdTS)
})

export const getVoucherListColumns = (artistMap, t, locale = 'de') => {
    const valueFormatter = createValueFormatter(locale)
    return [
        {field: 'VouchOutID', type: 'number'}
        , {field: 'VouchNo', type: 'number', headerName: t('VOUCHERS.VOUCHNO_LABEL', {ns: 'vouchers'})}
        , {
            field: 'DatOut',
            type: 'date',
            headerName: t('VOUCHERS.DATOUT_LABEL', {ns: 'vouchers'}),
            valueGetter: ({value}) => value && new Date(value)
        }
        , {field: 'Receiver', headerName: t('VOUCHERS.RECEIVER_LABEL', {ns: 'vouchers'})}
        , {field: 'Amount', headerName: t('VOUCHERS.AMOUNT_LABEL', {ns: 'vouchers'}), valueFormatter}
        , {
            field: 'ArtistID',
            headerName: t('VOUCHERS_LIST.BEZOGENER_ARTIST', {ns: i18_LIST}),
            valueGetter: ({value}) => value && artistMap[value].Kuerzel
        }
        , {
            field: '_createdTS',
            type: 'dateTime',
            headerName: t('CREATED_TIME_LABEL', {ns: i18_TRANSLATION}),
            valueGetter: ({value}) => value && new Date(value),
            minWidth: 150
        }
        , {
            field: '_createdID',
            headerName: t('CREATED_KUERZEL_LABEL', {ns: i18_TRANSLATION}),
            valueGetter: ({value}) => value && artistMap[value]?.Kuerzel || ''
        }
        , {field: 'Stichtagssaldo', headerName: t('VOUCHERS_LIST.STICHTAGSSALDO_LABEL', {ns: i18_LIST}), valueFormatter}
        , {
            field: 'Saldo',
            type: 'number',
            headerName: t('VOUCHERS_LIST.AKTUELLER_SALDO_LABEL', {ns: i18_LIST}),
            valueFormatter
        }
    ]
}

export const getVouchInListColumns = (t, locale) => {
    const valueFormatter = createValueFormatter(locale)
    return [
        {field: 'VouchInID', type: 'number'}
        , {field: 'VouchNo', type: 'number', headerName: t('VOUCHERS.VOUCHNO_LABEL', {ns: 'vouchers'})}
        , {
            field: 'DatOut',
            type: 'date',
            headerName: t('VOUCHERS.DATOUT_LABEL', {ns: 'vouchers'}),
            valueGetter: ({value}) => value && new Date(value),
            minWidth: 150
        }
        , {
            field: 'DatIn',
            type: 'date',
            headerName: t('VOUCHERS.DATIN_LABEL', {ns: 'vouchers'}),
            valueGetter: ({value}) => value && new Date(value),
            minWidth: 150
        }
        , {field: 'Encashed', headerName: t('VOUCHERS.ENCASHED_LABEL', {ns: 'vouchers'})}
        , {field: 'Amount', headerName: t('VOUCHERS.VOUCHIN_AMOUNT_LABEL', {ns: 'vouchers'}), valueFormatter}
        , {
            field: '_createdTS',
            type: 'dateTime',
            headerName: t('CREATED_TIME_LABEL', {ns: i18_TRANSLATION}),
            valueGetter: ({value}) => value && new Date(value),
            minWidth: 150
        }
    ]
}

export const createVoucherSchema = () => {
    return yup.object().shape({
        DatOut: yup.date().required(),
        Amount: yup.number().required().when(['$vouchInAmountSum'], ([vouchInAmountSum], schema) => schema.min(Math.max(vouchInAmountSum, 0))),
        ArtistID: yup.number().required(),
        Receiver: yup.string().max(40).required()
    })
}

export const createVouchInSchema = () => {
    return yup.object().shape({
        DatIn: yup.date().required(),
        Amount: yup.number().required().when(['$VoucherAmount'], ([voucherAmount], schema) => schema.max(voucherAmount)),
        Encashed: yup.string().max(15).required()
    })
}


export const convertVouchInSchema = (vouchIn) => ({
    ...vouchIn
    , DatIn: vouchIn.DatIn.toISOString()
})

export const convertVoucherSchema = (voucher) => ({
    ...voucher
    , DatOut: voucher.DatOut.toISOString()
})

