import {createSimpleAction, createTypeAction} from "../utils/actionUtils";

export const LOAD_VOUCHER_DATA = 'LOAD_VOUCHER_DATA'
export const loadVoucherDataAction = createSimpleAction(LOAD_VOUCHER_DATA, 'voucherNo')

export const LOAD_VOUCHER_DATA_SUCCESS = 'LOAD_VOUCHER_DATA_SUCCESS'
export const loadVoucherDataSuccessAction = createSimpleAction(LOAD_VOUCHER_DATA_SUCCESS, 'voucherData')

export const LOAD_VOUCHER_DATA_FAILURE = 'LOAD_VOUCHER_DATA_FAILURE'
export const loadVoucherDataFailureAction = createTypeAction(LOAD_VOUCHER_DATA_FAILURE)

export const ADD_VOUCHER_DATA = 'ADD_VOUCHER_DATA'
export const addVoucherDataAction = createSimpleAction(ADD_VOUCHER_DATA, 'voucherData')

export const CLEAR_VOUCHER_DATA = 'CLEAR_VOUCHER_DATA'
export const clearVoucherData = createTypeAction(CLEAR_VOUCHER_DATA)

export const SAVE_VOUCHER = 'SAVE_VOUCHER'
export const saveVoucherAction = createSimpleAction(SAVE_VOUCHER, 'voucher')

